import { gql } from "@apollo/client";

const SET_ACADEMY_TICKET_POLICY = gql`
  mutation SetAcademyTicketPolicy($academyId: String!, $policy: JSON!) {
    setAcademyTicketPolicy(academyId: $academyId, policy: $policy) {
      success
      error {
        code
        message
      }
      academy {
        id
        name
        ticketPolicies {
          name
          comment
          policy
        }
        usePayment
      }
      responseTime
    }
  }
`;

export default SET_ACADEMY_TICKET_POLICY;
