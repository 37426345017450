import React, {useState, useEffect, useMemo} from 'react'
import {DatePicker, InputNumber, Segmented, Space, Table, Tag, Typography} from "antd";

import styles from './style.module.scss'
import config from "../../config";
import moment from "moment/moment";

const Dashboard = ({classroomData}) => {
    const [endDate, setEndDate] = useState(null);
    const timeScopeOptions = [{label: '주', value: 'w'}, {label: '월', value: 'M'}]
    const [timeScopeData, setTimeScopeData] = useState('w')
    const [timeNumberInputValue, setTimeNumberInputValue] = useState(5)
    const [classList, setClassList] = useState(null);
    const [bookList, setBookList] = useState(null);
    // const [classroomNewUserChartData, setClassroomNewUserChartData] = useState(null);
    const [signedUpUsers, setSignedUpUsers] = useState(null);
    // const [classNewUserChartData, setClassNewUserChartData] = useState(null)

    useEffect(() => {
        let startDate;
        const getClassroomNewUserList = async () => {
            try {
                const res = await (await fetch(`${config.apiUrl}/admin/new/user`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({
                        startDate: moment(startDate).unix() * 1000,
                        endDate: endDate?.startOf('date').valueOf(),
                        classroomId: classroomData.value,
                    })
                })).json()
                setSignedUpUsers((prev) => ({...prev, classroom: res[0]?.HIQDB_member_classes}));
                // setClassroomNewUserChartData(res)
            } catch (e) {
                console.error(e);
            }
        }
        if (endDate && timeNumberInputValue && classroomData) {
            const endDateText = moment(endDate?.endOf('date').valueOf()).subtract(timeNumberInputValue, timeScopeData).format('YYYY-MM-DD')
            const checkTimeScope = timeScopeData === 'w' ? 'week' : 'month'
            startDate = moment(endDateText).startOf(checkTimeScope).format('YYYY-MM-DD')
            getClassroomNewUserList();
        }

    }, [endDate, classroomData, timeNumberInputValue, timeScopeData])

    useEffect(() => {
        console.log(moment())
        setEndDate(moment())
        const getClassList = async () => {
            try {
                const res = await (await fetch(config.apiUrl + `/admin/classroom/${classroomData.value}/class`)).json()
                setClassList(res);
            } catch (e) {
                console.error(e);
            }
        }
        const getBookList = async () => {
            try {
                const res = await (await fetch(config.apiUrl + `/admin/classroom/${classroomData.value}/book`)).json()
                setBookList(res);
            } catch (e) {
                console.error(e);
            }
        }
        if (classroomData) {
            getClassList()
            getBookList()
        }
    }, [classroomData])

    useEffect(() => {
        let startDate;
        const getNewUserList = async () => {
            try {
                const res = await (await fetch(`${config.apiUrl}/admin/new/user`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({
                        startDate: moment(startDate).unix() * 1000,
                        endDate: endDate?.startOf('date').valueOf(),
                        classList: classList.map(({id}) => id),
                    })
                })).json()
                const signedUpUser = res.map((classData) => ({
                    ...classData,
                    signedUpUsers: res[0].HIQDB_member_classes
                }));
                console.log('signedUpUser', signedUpUser)
                setSignedUpUsers((prev) => ({...prev, class: signedUpUser}))
                console.log(res)
            } catch (e) {
                console.error(e)
            }
        }
        if (classList && endDate && timeNumberInputValue) {
            const endDateText = moment(endDate?.endOf('date').valueOf()).subtract(timeNumberInputValue, timeScopeData).format('YYYY-MM-DD')
            const checkTimeScope = timeScopeData === 'w' ? 'week' : 'month'
            startDate = moment(endDateText).startOf(checkTimeScope).format('YYYY-MM-DD')
            getNewUserList()
        }
    }, [classList, endDate, timeNumberInputValue, timeScopeData])

    return <div className={styles.DashboardContainer}>
        <Space>
            <Segmented options={timeScopeOptions} defaultValue={timeScopeData} onChange={setTimeScopeData}/>
            <Typography.Text>기준 일</Typography.Text>
            <DatePicker
                placeholder={'start Date'}
                value={endDate}
                onChange={setEndDate}
                disabledDate={(current) => current > moment().endOf('day')}
            />
            <InputNumber
                min={1}
                max={500}
                defaultValue={timeNumberInputValue}
                onChange={setTimeNumberInputValue}
            />
            <Typography.Text>{timeScopeData === 'w' ? '주 전부터' : '개월 전부터'}</Typography.Text>
        </Space>
        <div>
            <Space style={{minWidth: '300px'}}>
                <div>
                    <Typography.Title title={2}>개설 수업</Typography.Title>
                    <Table dataSource={classList} rowKey={(record) => record.id}>
                        <Table.Column title={'수업 명'} dataIndex={'name'} align={"center"}/>
                    </Table>
                </div>
                <div>
                    <Typography.Title title={2}>개설 교재</Typography.Title>
                    <Table dataSource={bookList} rowKey={(record) => record.id} pagination={{pageSize: 5}}>
                        <Table.Column title={'교재명'} dataIndex={'name'} align={"center"}/>
                    </Table>
                </div>
            </Space>
        </div>

        <div>
            <Typography.Title title={2}>학원 가입 신청자 목록</Typography.Title>
            <Table dataSource={signedUpUsers?.classroom} rowKey={(record) => record.id} pagination={{pageSize: 5}}>
                <Table.Column title={'이름'} dataIndex={['mid_HIQDB_user_credential', 'user', 'name']} align={"center"}/>
                <Table.Column title={'가입 신청 일'} dataIndex={'reg_date'}
                              render={(reg_date) => <span>{moment(reg_date * 1000).format('YY.MM.DD')}</span>}
                              align={"center"}/>
                <Table.Column title={'상태'} dataIndex={'status'} render={(status) => {
                    if (status === 0) return <Tag>미승인</Tag>
                }} align={"center"}/>
            </Table>
        </div>
        <div>
            <Typography.Title title={2}>수업별 가입 신청자 목록</Typography.Title>
            <Table dataSource={signedUpUsers?.class} rowKey={(record) => record.id} pagination={{pageSize: 5}}
                   expandable={{
                       expandedRowRender: (classData) => {
                           const columns = [
                               {
                                   title: '이름',
                                   dataIndex: ['mid_HIQDB_user_credential', 'user','name'],
                                   key: ['mid_HIQDB_user', 'name'],
                                   align: 'center'
                               },
                               {
                                   title: '선생님 / 학생',
                                   dataIndex: ['mid_HIQDB_user_credential', 'user', 'user_type'],
                                   key: ['mid_HIQDB_user', 'user_type'],
                                   render: (userType) => {
                                       let userTypeText = '선생님'
                                       if (userType === 1) userTypeText = '학생'

                                       return <span>{userTypeText}</span>
                                   },
                                   align: 'center'
                               },
                               {
                                   title: '가입 신청일',
                                   dataIndex: 'reg_date',
                                   key: 'reg_Date',
                                   render: (reg_date) => <span>{moment(reg_date * 1000).format('YY.MM.DD')}</span>
                                   ,
                                   align: 'center'
                               },
                               {
                                   title: '상태',
                                   dataIndex: 'status',
                                   key: 'status',
                                   render: (status) => {
                                       if (status === 0) return <Tag>미승인</Tag>
                                   },
                                   align: 'center'
                               }
                               ,
                           ];
                           return <Table columns={columns} dataSource={classData.signedUpUsers}
                                         pagination={false}/>;
                       },
                       defaultExpandedRowKeys: ['0'],
                   }}>
                <Table.Column title={'이름'} dataIndex={'name'} align={"center"}/>
                <Table.Column title={'신청자 수'} dataIndex={'signedUpUsers'} render={(member) => {
                    return <span>{member?.length}</span>
                }} align={"center"}/>
            </Table>
        </div>
    </div>
}

export default Dashboard
