import { gql } from '@apollo/client';

const GIVE_BASIC_TICKET = gql`
mutation GiveBasicTicketToStudent($academyId: String!, $studentId: String!, $ticketType: String!, $quantity: Int!) {
  giveBasicTicketToStudent(academyId: $academyId, studentId: $studentId, ticketType: $ticketType, quantity: $quantity) {
    error {
      code
      message
    }
    success
  }
}
`
export default GIVE_BASIC_TICKET;