import React, { useContext, useEffect, useState, useCallback } from "react";
import styles from "./style.module.scss";
import TicketPolicySection from "./TicketPolicySection";
import GiveTicketSection from "./GiveTicketSection";
import useAcademy from "hooks/useAcademy";

const TicketPage = ({ classroomData }) => {
  const academyId = classroomData.value;
  const academy = useAcademy(academyId);
  return (
    <div className={styles.ticketContainer}>
      <TicketPolicySection academyId={academyId} academy={academy} />
      <GiveTicketSection academyId={academyId} academy={academy} />
    </div>
  );
};

export default TicketPage;
