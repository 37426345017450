import { gql } from "@apollo/client";

const UPDATE_PAYBY_POLICY = gql`
  mutation UpdatePaybyPolicy(
    $policyId: Int!
    $ratio: Float!
    $academyId: String!
  ) {
    updatePaybyPolicy(policyId: $policyId, ratio: $ratio, academyId: $academyId)
  }
`;

export default UPDATE_PAYBY_POLICY;
