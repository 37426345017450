import { useEffect, useState } from "react";
import { useQuery } from "@apollo/client";
import GET_ACADEMY from "graphql/getAcademy";

function useAcademy(academyId) {
  const [academy, setAcademy] = useState(null);
  const { data } = useQuery(GET_ACADEMY, {
    fetchPolicy: "network-only",
    variables: { academyId: String(academyId) },
  });

  const queryAcademyData = data?.getAcademy?.academy;

  useEffect(() => {
    if (queryAcademyData) {
      setAcademy(queryAcademyData);
    }
  }, [queryAcademyData]);

  return academy;
}

export default useAcademy;
