import {useLocation, useNavigate} from "react-router-dom";
import React, {useContext, useEffect, useRef} from "react";
import axios from "axios";
import config from "../../config";
import {Button, ConfigProvider, Space} from "antd";
import KakaoLogo from 'img/kakaotalk_sharing_btn/kakaotalk_sharing_btn_small.png'
import styles from "./style.module.scss";
import {StoreContext} from "../../store";
import {observer} from "mobx-react-lite";

const Login = ({setUserInfo, userInfo}) => {
    const {Kakao, naver} = window;
    const naverRef = useRef();
    const navigate = useNavigate();
    const lastSocial = window.localStorage.getItem('lastSocial')
    const location = useLocation();
    const UserInfo = useContext(StoreContext)
    const clickKakaoLoginBtn = () => {
        window.localStorage.setItem('url', location.pathname)
        window.localStorage.setItem('lastSocial', 'Kakao');
        Kakao.Auth.authorize({
            redirectUri: `${config.redirect_url}`,
        })
    }
    const authKakaoLogin = async () => {
        try {
            const kakaoCode = new URL(window.location.href).searchParams.get("code");
            const resToken = await axios({
                method: "POST",
                url: 'https://kauth.kakao.com/oauth/token',
                headers: {
                    'Content-type': 'application/x-www-form-urlencoded;charset=utf-8',
                },
                data: {
                    grant_type: 'authorization_code',
                    client_id: config.kakao_client_id,
                    redirect_uri: config.redirect_url,
                    code: kakaoCode
                }
            })
            Kakao.Auth.setAccessToken(resToken.data.access_token);

            const resData = await Kakao.API.request({
                url: "/v2/user/me",
            })

            getProviderSignIn(resData, 'Kakao');
        } catch (e) {
            console.log(e.response.status)
        }
    }
    const postLoginTestPark = async ()=>{
        try {
            const {data} = await axios.post(`${config.apiUrl}/admin/login`, {
                id: '0YtcNZweMgR0PkembMND',
                pw: 'TqXFCKZWbnYkBUP4/rBv1Fd3e+OVScQBZDav2mXSMw4='
            });
            UserInfo.setUser(data);
            window.localStorage.setItem("user", JSON.stringify(data));
        } catch (e) {
            console.error(e);
        }
    }
    const getProviderSignIn = async (userAuthData , provider) => {
        try {
            const {data} = await axios.post(`${config.apiUrl}/admin/login`, {
                userAuthData,
                provider,
            });
            UserInfo.setUser(data);
            window.localStorage.setItem("user", JSON.stringify(data));
        } catch (e) {
            console.error(e);
            alert('로그인 실패했습니다.')
        }
    }

    useEffect(() => {
        const getnaverLogin = () => {
            try {
                const naverLogin = new naver.LoginWithNaverId({
                    clientId: config.naver_client_id,
                    callbackUrl: config.redirect_url,
                    isPopup: false, /* 팝업을 통한 연동처리 여부, true 면 팝업 */
                    loginButton: {color: "green", type: 3, height: 52, width: 200},
                });
                naverLogin.init();
                naverLogin.getLoginStatus((status) => {
                    if (status && lastSocial === 'naver') {
                        getProviderSignIn(naverLogin.user, 'naver')
                    }
                })
            } catch (e) {
                console.error(e);
            }
        }
        if (!userInfo) {
            getnaverLogin();
        }
    }, [])
    useEffect(() => {
        if (window.location.href.includes('?code')) {
            authKakaoLogin();
        }
    }, [navigate]);

    return (
        <div className={styles.LoginContainer}>
            <Space direction="vertical">
                <div style={{width: 300}}>
                    <div ref={naverRef}
                         id="naverIdLogin"
                         style={{
                             display: "none",
                             width: "100%",
                             justifyContent: "center",
                         }}/>
                    <ConfigProvider
                        theme={{
                            token: {
                                colorPrimary: "#2DB400",
                                colorBgContainer: '#2DB400',
                                colorText: '#ffffff'
                            }
                        }}>
                        <Button
                            block={true}
                            size="large"
                            icon={<img
                                width={24}
                                height={24}
                                alt="Naver Logo"/>}
                            onClick={() => {
                                window.localStorage.setItem('lastSocial','naver')
                                naverRef?.current.children[0].click()
                            }}
                        >
                            네이버로 로그인
                        </Button>
                    </ConfigProvider>
                </div>

                <div style={{width: 300}}>
                    <ConfigProvider
                        theme={{
                            token: {
                                colorPrimary: "#f9e000",
                                colorBgContainer: '#f9e000',
                            }
                        }}>
                        <Button
                            block={true}
                            size="large"
                            icon={<img
                                src={KakaoLogo}
                                width={16}
                                height={16}
                                alt="Kakao Talk Logo"/>}
                            onClick={clickKakaoLoginBtn}
                        >
                            카카오톡으로 로그인
                        </Button>


                    </ConfigProvider>
                    {process.env.NODE_ENV !== 'production' && <Button
                        onClick={postLoginTestPark}
                    >Park juho</Button>}
                </div>
            </Space>
        </div>
    )
}

export default observer(Login)