
let isDev = false // dev on/off
let isInspect = false // inspect on/off

let apiUrl = ""
let wsurl = ""
let redirect_url = ""
let s3_url = ""

const classroomId =  396
const ownerId =  'Ud22n4hR9Pyg5x5WtHaL'

if (process.env.NODE_ENV === 'production') {
    apiUrl = `https://api.hiq24.co.kr` // internal
    wsurl = `wss://api.hiq24.co.kr` // internal
    redirect_url = 'https://admin.hiq24.co.kr/' // internal
    s3_url = "https://api.hiq24.co.kr/resource"
    isDev = true
} else {
    apiUrl = `http://localhost:3000` // server
    wsurl = `ws://localhost:8082` // websocket
    redirect_url = 'http://localhost:3001/' // front
    s3_url = "http://localhost:3000" // upload gcp
    isDev = true
}


const constants = {
    isDev: isDev,
    s3_url: s3_url,
    // wsurl : `ws://com.hiq24.co.kr:8061`, // internal
    apiUrl: apiUrl, // api 호출 Url
    wsurl: wsurl,  // 소켓
    redirect_url: redirect_url, //
    kakao_client_id: 'ba17d32598e9f839a6f17b228b3fef64',
    naver_client_id: 'RTGpA0hENM99AhGP0fNm',
    session: "9ea14cde-9905-11ea-bb37-0242ac130003",
    classroomId,
    ownerId
}

export default constants
