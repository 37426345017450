import React from "react";
import { ApolloClient, ApolloProvider, ApolloLink, HttpLink, InMemoryCache, concat } from "@apollo/client";
import config from "../config";
import { loadErrorMessages, loadDevMessages } from "@apollo/client/dev";

if ('production' !== process.env.NODE_ENV) {  // Adds messages only in a dev environment
  loadDevMessages();
  loadErrorMessages();
}

const httpLink = new HttpLink({ uri: `${config.apiUrl}/admingraphql` });

const getUser = () => {
    try {
        if (window.localStorage.getItem("user")) {
            return JSON.parse(window.localStorage.getItem("user"));
        }
    } catch (e) { }
};

const authMiddleware = new ApolloLink((operation, forward) => {
    operation.setContext(({ headers = {} }) => ({
        headers: {
            ...headers,
            authorization: `Bearer ${getUser()?.jwt}`,
        }
    }));

    return forward(operation);
})

const client = new ApolloClient({
    link: concat(authMiddleware, httpLink),
    cache: new InMemoryCache(),
});


const ApolloClientProvider = ({ children }) => {
    return <ApolloProvider client={client}>{children}</ApolloProvider>;
};

export default ApolloClientProvider;
