import { gql } from '@apollo/client';

const ACADEMY_TEACHER_ACTIONS = gql`
query AcademyTeacherActions($academyId: String!, $period: PeriodArg) {
  academyTeacherActions(academyId: $academyId, period: $period) {
      teacher {
        id
        name
        nickname
      }
      logs {
        actedAt
        datetime
        action_type
        actionType
        quantity
        payload
      }
    }
  }
`;

export default ACADEMY_TEACHER_ACTIONS;