import { gql } from '@apollo/client';

const ALL_PAYBY_POLICIES = gql`
query AllPaybyPolicies($academyId: String!, $startDate: String) {
  allPaybyPolicies(academyId: $academyId, startDate: $startDate) {
    title
    actionType
    isSet
    policy {
      id
      title
      desc
      actionType
      ratio
      validFrom
      currentMonth
    }
  }
}
`;

export default ALL_PAYBY_POLICIES;


