import React, { useContext, useEffect, useMemo, useState } from "react";
import { observer } from "mobx-react-lite";
import "./App.css";
import { Link, NavLink, Route, Routes, useNavigate } from "react-router-dom";
import { StoreContext } from "store";
import Login from "pages/Login";
import { ConfigProvider, Layout, Menu, Select, Space, theme } from "antd";
import Dashboard from "./pages/Dashboard";
import TeacherReport from "./pages/TeacherReport";
import StudentReport from "./pages/StudentSearch";
import TeacherDetailReport from "./pages/TeacherDetailReport";
import Ticket from "./pages/Ticket";
import Bill from "./pages/Bill";
import Policy from "pages/Policy";
import { useQuery } from "@apollo/client";
import GET_ALL_ACADEMY_BY_USER from "graphql/getAllAcademyByUser";
import Ready from "pages/ready";

function App() {
  const UserInfo = useContext(StoreContext);
  const navigator = useNavigate();
  const [selectAcademy, setSelectAcademy] = useState(null);
  const [tabs, setTabs] = useState();

  const { data } = useQuery(GET_ALL_ACADEMY_BY_USER, {
    fetchPolicy: "network-only",
    variables: { userId: UserInfo.user?.id },
  });

  const { academies, academyOptions } = useMemo(() => {
    let academies = [];
    let academyOptions = [];
    if (data?.getAllAcademyByUser?.academies) {
      academies = data.getAllAcademyByUser.academies;
      academyOptions = data.getAllAcademyByUser.academies.map((academy) => ({
        label: academy.name,
        value: parseInt(academy.id),
      }));
    }
    return { academies, academyOptions };
  });

  useEffect(() => {
    if (!academies || !selectAcademy) return;
    const academy = academies.find(
      (academy) => parseInt(academy.id) === selectAcademy?.value
    );
    console.log("academy type", academy?.type);
    if (academy?.type === 5) {
      setTabs(items);
    } else if (academy?.type === 6 || academy?.type === 7) {
      const filteredItems = items.filter(
        (item) => item.key === "0" || item.key === "2"
      );
      setTabs(filteredItems);
    }
  }, [selectAcademy]);

  const items = [
    { key: "0", label: <Link to={"/"}>대시 보드</Link> },
    {
      key: "1",
      label: (
        <NavLink to={selectAcademy ? "/teacher" : ""}>선생님 현황</NavLink>
      ),
      disabled: selectAcademy ? false : true,
    },
    {
      key: "2",
      label: (
        <NavLink to={selectAcademy ? "/student" : ""}>학생 레포트</NavLink>
      ),
      disabled: selectAcademy ? false : true,
    },
    {
      key: "3",
      label: <NavLink to={selectAcademy ? "/ticket" : ""}>질문권 관리</NavLink>,
      disabled: selectAcademy ? false : true,
    },
    {
      key: "4",
      label: <NavLink to={selectAcademy ? "/bill" : ""}>청구서</NavLink>,
      disabled: selectAcademy ? false : true,
    },
    {
      key: "5",
      label: <NavLink to={selectAcademy ? "/policy" : ""}>정책 설정</NavLink>,
      disabled: selectAcademy ? false : true,
    },
  ];

  useEffect(() => {
    if (!selectAcademy) {
      navigator("/");
    }
  }, [selectAcademy]);

  if (!UserInfo?.user) {
    return (
      <>
        <Login />
      </>
    );
  }

  return (
    <div className="App">
      <ConfigProvider
        theme={{
          algorithm: theme.compactAlgorithm,
          token: {
            colorPrimary: "#F5B21A",
            colorInfo: "#F5B21A",
            colorSuccess: "#F5B21A",
          },
        }}
      >
        <Layout>
          <Layout.Header
            style={{ display: "flex", justifyContent: "space-between" }}
          >
            <Link to={"/"} className="mainLogo">
              {selectAcademy?.label}
            </Link>
            <Space>
              <Select
                options={academyOptions}
                defaultValue={"학원을 선택해주세요."}
                style={{ minWidth: "100px" }}
                onChange={(_, target) => setSelectAcademy(target)}
              />
              <Menu
                items={tabs}
                theme={"dark"}
                mode={"horizontal"}
                defaultSelectedKeys={"0"}
                style={{ width: tabs?.length > 3 ? "400px" : "200px" }}
              />
            </Space>
          </Layout.Header>
          <Layout.Content
            style={{ minHeight: "60vh", backgroundColor: "white" }}
          >
            <div style={{ maxWidth: "70vw", margin: "0 auto" }}>
              <Routes>
                <Route
                  element={<Dashboard classroomData={selectAcademy} />}
                  path={"/"}
                />
                <Route
                  element={<TeacherReport classroomData={selectAcademy} />}
                  path={"/teacher"}
                />
                <Route
                  element={
                    <TeacherDetailReport classroomData={selectAcademy} />
                  }
                  path={"/teacher/:id"}
                />
                <Route
                  element={<StudentReport classroomData={selectAcademy} />}
                  path={"/student"}
                />
                <Route
                  element={<Ticket classroomData={selectAcademy} />}
                  path={"/ticket"}
                />
                {/* TODO: 결제 기능 도입 시 적용 */}
                {/* <Route
                  element={<Bill classroomData={selectAcademy} />}
                  path={"/bill"}
                /> */}
                <Route element={<Ready />} path={"/bill"} />
                <Route
                  element={<Policy classroomData={selectAcademy} />}
                  path={"/policy"}
                />
              </Routes>
            </div>
          </Layout.Content>
          <Layout.Footer style={{ textAlign: "right" }}>
            Powered by HiQSum
          </Layout.Footer>
        </Layout>
      </ConfigProvider>
    </div>
  );
}

export default observer(App);
