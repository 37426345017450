import {useEffect, useState} from "react";

const useDebounce = (value, milliSeconds) => {
    const [debouncedValue, setDebounceValue] = useState(value);

    useEffect(() => {
        const handler = setTimeout(() => {
            setDebounceValue(value)
        }, milliSeconds);
        return () => {
            clearTimeout(handler);
        }
    }, [value, milliSeconds])

    return debouncedValue
}
export default useDebounce;