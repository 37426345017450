import React, { useState, useCallback, useMemo } from "react";
import { useMutation, useQuery } from "@apollo/client";
import {
  InputNumber,
  Button,
  Space,
  Typography,
  Modal,
  Spin,
  Table,
} from "antd";
import styles from "./style.module.scss";
import GET_ALL_STUDENTS from "graphql/getAllStudents";
import GIVE_BASIC_TICKET from "graphql/giveBasicTicketToStudent";
import Swal from "sweetalert2";
import SearchInput from "components/SearchInput";
const { Text } = Typography;

const GiveTicketSection = ({ academy, academyId }) => {
  const [isOpen, setOpen] = useState(false);
  const [modalTitle, setModalTitle] = useState();
  const [inputValue, setInputValue] = useState(new Map());
  const [target, setTarget] = useState({});
  const [searchValue, setSearchValue] = useState("");

  const { loading, data, refetch } = useQuery(GET_ALL_STUDENTS, {
    fetchPolicy: "network-only",
    variables: { classId: academyId },
  });
  const [giveBasicTicketToStudent] = useMutation(GIVE_BASIC_TICKET);

  const filteredData = useMemo(() => {
    if (!data) {
      return [];
    }
    if (!searchValue) {
      return data?.getAllStudents;
    }
    return data?.getAllStudents.filter((item) =>
      item.user.name.includes(searchValue)
    );
  }, [data, searchValue]);

  const columns = [
    {
      title: "학생명",
      dataIndex: "user",
      key: "user",
      align: "center",
      width: "150px",
      render: (user) => (
        <Space direction="vertical">
          <Text>
            {user.name}({user.nickname})
          </Text>
          <Text>{user.intro}</Text>
        </Space>
      ),
    },
    {
      title: "일일 질문권",
      dataIndex: "inventory",
      key: "dailyQuantity",
      align: "center",
      width: "80px",
      render: (inventory) => <Text>{inventory.quantity}</Text>,
    },
    {
      title: "월간 질문권",
      dataIndex: "inventory",
      key: "monthlyQuantity",
      align: "center",
      width: "80px",
      render: (inventory) => <Text>{inventory.monthQuantity}</Text>,
    },
    {
      title: "수업명",
      dataIndex: "class",
      key: "className",
      align: "center",
      width: "180px",
      render: (classData) => <Text>{classData.name}</Text>,
    },
    {
      title: "일일 질문권 추가 지급",
      key: "action",
      align: "center",
      width: "100px",
      render: (_, record) => (
        <Space direction="horizontal" align="center">
          <InputNumber
            min={0}
            onChange={onChangeNumber(record.user.id)}
            defaultValue={0}
          />
          <Button onClick={onClickGive(record.user.id)}>추가</Button>
        </Space>
      ),
    },
  ];

  const onChangeNumber = useCallback(
    (id) => (value) => {
      inputValue.set(id, value);
    },
    [inputValue]
  );

  const onClickGive = useCallback(
    (id) => (e) => {
      const value = inputValue.get(id);
      if (!value) {
        return;
      }
      setTarget({
        id,
        value,
      });
      const item = data.getAllStudents.filter((item) => item.user.id === id)[0];
      setModalTitle(
        `${item.user.name} 학생에게 기본 질문권 ${value}개를 부여합니다.`
      );
      setOpen(true);
    },
    [inputValue, data]
  );

  const handleOk = useCallback(async () => {
    const variables = {
      academyId: academy.id,
      studentId: target.id,
      quantity: target.value,
      ticketType: "BASIC",
    };
    try {
      await giveBasicTicketToStudent({ variables });
      setTarget({});
      setOpen(false);
      Swal.fire("질문권 지급 성공", "질문권이 지급되었습니다.", "success").then(
        () => {
          refetch();
        }
      );
    } catch (error) {
      Swal.fire("질문권 지급 실패", "질문권 지급에 실패하였습니다.", "error");
    }
  }, [target, academy, giveBasicTicketToStudent, refetch]);

  const handleCancel = useCallback(() => {
    setTarget({});
    setOpen(false);
  }, []);

  const handleSearch = (value) => {
    setSearchValue(value);
  };

  if (loading || !academy) {
    return <Spin />;
  }

  return (
    <div className={styles.ticketPolicySection}>
      <div style={{ marginBottom: "20px" }}>
        <SearchInput
          placeholder="학생명"
          onSearch={handleSearch}
          style={{ width: "200px" }}
        />
      </div>
      <Table
        columns={columns}
        dataSource={filteredData}
        rowKey={(record) => record.user.id}
      />
      <Modal
        title={modalTitle}
        open={isOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        afterClose={handleCancel}
        cancelText={"취소"}
        okText={"확인"}
      />
    </div>
  );
};

export default GiveTicketSection;
