import { gql } from "@apollo/client";

const GET_ALL_STUDENTS = gql`
  query GetAllStudents($classId: Int!) {
    getAllStudents(classId: $classId) {
      class {
        id
        name
      }
      user {
        id
        type
        provider
        name
        nickname
        intro
        img
      }
      inventory {
        id
        tid
        quantity
        monthQuantity
        state
      }
    }
  }
`;

export default GET_ALL_STUDENTS;
