import { gql } from "@apollo/client";

const GET_ACADEMY = gql`
  query GetAcademy($academyId: String!) {
    getAcademy(academyId: $academyId) {
      academy {
        id
        name
        location
        phone
        principal
        ticketPolicies {
          name
          comment
          policy
        }
        usePayment
      }
      error {
        code
        message
      }
      success
      responseTime
    }
  }
`;

export default GET_ACADEMY;
