import {Button, DatePicker, Image, Input, InputNumber, Segmented, Space, Table, Tag, Typography, Radio} from "antd";
import moment from "moment";
import React, {useCallback, useContext, useEffect, useState} from "react";
import config from "../../config";
import {StoreContext} from "../../store";
import {toJS} from "mobx";
import useDebounce from "../../hooks/useDebounce";
import styles from './style.module.scss'
import {CartesianGrid, Legend, Line, LineChart, ResponsiveContainer, Tooltip, XAxis, YAxis} from "recharts";

moment.updateLocale('ko', {
    week: {
        dow: 1
    }
})
const StudentSearch = ({classroomData}) => {
    const [endDate, setEndDate] = useState(null);
    const [timeScopeData, setTimeScopeData] = useState('w')
    const [timeNumberInputValue, setTimeNumberInputValue] = useState(5)
    const [searchStudentNameValue, setSearchStudentNameValue] = useState('')
    const [studentReportData, setStudentReportData] = useState(null)
    const [studentQnaList, setStudentQnaList] = useState(null)
    const [studentChartData, setStudentChartData] = useState(null)
    const [studentJoinClass, setStudentJoinClass] = useState([{label: '전체', value: '전체'}])
    const [selectClassData, setSelectClassData] = useState('전체')
    const UserInfo = useContext(StoreContext)
    const searchQuery = useDebounce(searchStudentNameValue, 1000)
    const timeScopeOptions = [{label: '주', value: 'w'}, {label: '월', value: 'M'}]

    useEffect(() => {
        let startDate;
        const getStudentReportData = async () => {
            try {
                const studentReport = await (await fetch(config.apiUrl + '/admin/student/report', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({
                        studentName: searchQuery,
                        startDate: moment(startDate).unix() * 1000,
                        endDate: endDate?.startOf('date').valueOf(),
                        classroomId: classroomData?.value,
                        classId: selectClassData !== '전체' ? selectClassData : null
                    })
                })).json()
                setStudentQnaList(studentReport?.qnaList?.map((qna, index) => {
                        return {
                            ...qna,
                            columnsId: index + 1
                        }
                    })
                )
                setStudentReportData(() => {
                    let copyStudentReport = studentReport
                    delete copyStudentReport.qnaList
                    return copyStudentReport
                })
                adapterAnswerLog(studentReport.qnaList)
            } catch (e) {
                alert('해당 학생이 학원에 가입되어 있지 않습니다.')
                console.error(e)
                setStudentQnaList(null)
                setStudentReportData(null)
            }
        }

        function parseDateStr(dateStr, type) {
            if (type === "w") {
                const parts = dateStr.match(/(\d+)년 (\d+)월 (\d+)주차/);
                return parts ? {
                    year: parseInt(parts[1], 10),
                    month: parseInt(parts[2], 10),
                    week: parseInt(parts[3], 10)
                } : null;
            } else if (type === "M") {
                const parts = dateStr.match(/(\d+)년 (\d+)월/);
                return parts ? {
                    year: parseInt(parts[1], 10),
                    month: parseInt(parts[2], 10),
                    week: 0 // 월만 있는 경우 주차를 0으로 처리
                } : null;
            }
        }

        function compareDates(a, b, type) {
            const dateA = parseDateStr(a, type);
            const dateB = parseDateStr(b, type);

            if (dateA.year !== dateB.year) {
                return dateA.year - dateB.year;
            } else if (dateA.month !== dateB.month) {
                return dateA.month - dateB.month;
            } else {
                return dateA.week - dateB.week;
            }
        }

        const adapterAnswerLog = (answer) => {
            const setList = [];
            answer?.map((qnaData) => {
                const {reg_date, understand, level, ans_date} = qnaData
                const dateFormat = timeScopeData === 'w' ? 'YYYYMMDD' : 'YYYYMM'
                const date = moment.unix(qnaData.reg_date).format(dateFormat)
                const week = moment(date, 'YYYYMMDD').week() - moment(date).startOf('month').week() + 1;
                const month = moment(date).month() + 1;
                const year = moment(date).year();
                const key = timeScopeData === 'w' ? `${year}년 ${month}월 ${week}주차` : `${year}년 ${month}월`
                const findIndex = setList.findIndex(({time}) => time === key)
                if (findIndex > -1) {
                    setList[findIndex]['qnaCount'] += 1
                    setList[findIndex]['underStand'] += understand === 'y' ? 1 : 0
                    setList[findIndex]['ansCount'] += ans_date ? 1 : 0
                    if (level) setList[findIndex]['qna_level'].push(level)
                    setList[findIndex]['avgLevel'] = (setList[findIndex]['qna_level'].reduce((acc, levelData) => acc + levelData, 0) / setList[findIndex]['qnaCount']).toFixed(1)
                    if (ans_date) setList[findIndex]['avgResponseTime'].push((moment(ans_date * 1000).diff(reg_date * 1000, 'minutes') / 60).toFixed(1))
                } else {
                    setList.push({
                        time: key,
                        qnaCount: 1,
                        underStand: understand === 'y' ? 1 : 0,
                        ansCount: ans_date ? 1 : 0,
                        qna_level: level ? [level] : [],
                        avgLevel: 0,
                        avgResponseTime: ans_date ? [(moment(ans_date * 1000).diff(reg_date * 1000, 'minutes') / 60).toFixed(1)] : []
                    })
                }
            })
            setStudentChartData(setList.sort((a, b) => compareDates(a.time, b.time, timeScopeData)))
        }

        if (endDate && timeNumberInputValue && searchQuery && selectClassData) {
            const endDateText = moment(endDate?.endOf('date').valueOf()).subtract(timeNumberInputValue, timeScopeData).format('YYYY-MM-DD')
            const checkTimeScope = timeScopeData === 'w' ? 'week' : 'month'
            startDate = moment(endDateText).startOf(checkTimeScope).format('YYYY-MM-DD')
            getStudentReportData()
        }
    }, [endDate, timeScopeData, timeNumberInputValue, searchQuery, selectClassData, classroomData])

    useEffect(() => {
        const getStudentClass = async () => {
            try {
                const res = await (await fetch(config.apiUrl + `/admin/classroom/${classroomData?.value}/class?${studentReportData?.id ? `userId=${studentReportData?.id}` : ''}`)).json();
                setStudentJoinClass((prev) => {
                    const adapterClass = res.map((classData) => {
                        return {
                            label: classData.name,
                            value: classData.id
                        }
                    })
                    return [{label: '전체', value: '전체'}, ...adapterClass]
                })
            } catch (e) {
                console.error(e)
                alert('학생이 가입한 수업을 가져오는데 실패했습니다.')
            }
        }
        getStudentClass()
    }, [studentReportData, classroomData])


    const qnaColumns = [
        {
            title: '문제 번호',
            dataIndex: 'columnsId',
            ket: 'columnsId',
            render: (id) => <span>{id}</span>,
            align: "center",
        },
        {
            title: '질문 일시',
            dataIndex: 'reg_date',
            key: 'reg_date',
            render: (date) => <span>{moment(date * 1000).format('YY-MM-DD HH:mm')}</span>,
            align: "center",
        },
        Table.EXPAND_COLUMN,
        {
            title: '문제 사진',
            dataIndex: 'img',
            key: 'img',
            render: (img, record) => <span
                onClick={() => console.log('click')}>{(img || record.original_img) ? '사진 보기' : ''}</span>,
            align: "center",
        },
        {
            title: '답변 형태',
            dataIndex: 'HIQDB_online_qna_msgs',
            key: 'HIQDB_online_qna_msgs',
            render: (answerData) => {
                let answerText = ''
                const includeCheck = (type) => {
                    return answerText += answerText.includes(type) ? '' : (answerText.length === 0 ? type : `, ${type}`)
                }
                answerData?.forEach((answer) => {
                    const {ctype} = answer
                    if (ctype === 0) includeCheck('텍스트')
                    else if (ctype === 1) includeCheck('이미지')
                    else if (ctype === 2) includeCheck('외부링크(유튜브)')
                    else if (ctype === 3) includeCheck('영상')
                    else if (ctype === 3) includeCheck('링크')
                })
                return <span>{answerText}</span>
            },
            align: "center",
        },
        {
            title: '지목강사',
            dataIndex: ['tid_HIQDB_user_credential','user', 'name'],
            key: ['tid_HIQDB_user', 'name'],
            align: "center",
        },
        {
            title: '답변강사',
            dataIndex: ['answered_by_HIQDB_user_credential','user', 'name'],
            key: ['answered_by_HIQDB_user', 'name'],
            align: "center",
        },
        {
            title: '난이도',
            dataIndex: 'level',
            key: 'level',
            align: "center",
        },
        {
            title: '응답 시간 (시간)',
            dataIndex: 'ans_date',
            key: 'ans_date',
            render: (ans_date, record) => {
                if (ans_date) {
                    return (moment(ans_date * 1000).diff(record.reg_date * 1000, 'minutes') / 60).toFixed(1)
                }
            },
            align: "center",
        },
        {
            title: '좋아요',
            dataIndex: 'understand',
            key: 'understand',
            render: (value) => {
                return value === 'y' && <Tag>좋아요</Tag>
            },
            align: "center",
        },

    ]
    const chartColumns = [
        {
            title: () => `기간 (${timeScopeData === 'w' ? '주' : '월'}간)`,
            dataIndex: 'time',
            ket: 'time',
            align: "center",
        },
        {
            title: '전체 질문 수',
            dataIndex: 'qnaCount',
            key: 'qnaCount',
            align: "center",
        },
        {
            title: '답변 완료 질문 수',
            dataIndex: 'ansCount',
            key: 'ansCount',
            align: "center"
        },
        {
            title: '좋아요 수',
            dataIndex: 'underStand',
            key: 'underStand',
            align: "center",
        },
        {
            title: '문항 난이도 평균',
            dataIndex: 'avgLevel',
            key: 'avgLevel',
            align: "center",
        },
        {
            title: '평균 응답 시간 (시간)',
            dataIndex: 'avgResponseTime',
            key: 'avgResponseTime',
            render: (avgResponseTime, record) => {
                if (avgResponseTime.length === 0) return

                return <span>{(avgResponseTime?.reduce((acc, time) => acc + Number(time), 0) / record.ansCount).toFixed(1)}</span>
            },
            align: "center",
        },
    ]

    const strokeCOLOR = ['#F5B21A', '#487FBA', '#2b8649']
    return (
        <div className={styles.studentReportContainer}>
            <Space direction={'vertical'}>
                <Space direction={'vertical'}>
                    <Space>
                        <Segmented options={timeScopeOptions} defaultValue={timeScopeData} onChange={setTimeScopeData}
                                   style={{marginRight: '10px'}}/>
                        <Typography.Text>기준일</Typography.Text>
                        <DatePicker
                            placeholder={'start Date'}
                            value={endDate}
                            onChange={setEndDate}
                            disabledDate={(current) => current > moment().endOf('day')}
                            style={{width: '100px'}}
                        />
                        <InputNumber
                            min={1}
                            max={500}
                            defaultValue={timeNumberInputValue}
                            onChange={setTimeNumberInputValue}
                            style={{width: '50px'}}
                        />
                        <Typography.Text>{timeScopeData === 'w' ? '주 전부터' : '개월 전부터'}</Typography.Text>
                    </Space>
                </Space>
                <Input.Search placeholder={'학생 이름을 입력해주세요.'}
                              onChange={(e) => setSearchStudentNameValue(e.target.value)}
                              style={{maxWidth: '40%'}}
                />
                {studentJoinClass?.length >= 1 &&
                    <Radio.Group
                        options={studentJoinClass}
                        value={selectClassData}
                        style={{
                            marginTop: 16,
                        }}
                        optionType="button"
                        onChange={({target: {value}}) => setSelectClassData(value)}
                    />
                }
            </Space>
            {
                studentReportData &&
                <div className={styles.studentReportSection}>
                    <Space>
                        <Typography.Title title={2}>{studentReportData.name}</Typography.Title>
                    </Space>
                    {studentChartData?.length >= 1 ?
                        <div>
                            <div style={{width: '60vw', height: '400px', maxWidth: '60vw', margin: '0 auto'}}>
                                <ResponsiveContainer width="100%" height="100%">
                                    <LineChart
                                        width={500}
                                        height={300}
                                        data={studentChartData}
                                        margin={{
                                            top: 5,
                                            right: 30,
                                            left: 20,
                                            bottom: 5,
                                        }}
                                    >
                                        <CartesianGrid vertical={false}/>
                                        <XAxis dataKey="time" padding={{left: 40, right: 40}}/>
                                        <YAxis/>
                                        <Tooltip/>
                                        <Legend/>
                                        <Line dataKey={"qnaCount"} name={"전체 질문 수"} stroke={strokeCOLOR[0]}
                                              strokeWidth={3} connectNulls={true}/>
                                        <Line dataKey={"avgLevel"} name={"문항 난이도"} stroke={strokeCOLOR[1]}
                                              strokeWidth={3} connectNulls={true}/>
                                        {/*<Line dataKey={"underStand"} name={"이해 완료 수"} stroke={strokeCOLOR[2]}*/}
                                        {/*      strokeWidth={3} connectNulls={true}/>*/}
                                    </LineChart>
                                </ResponsiveContainer>
                            </div>
                            <div>
                                <Table
                                    rowKey={(record) => record?.cid}
                                    dataSource={studentChartData}
                                    columns={chartColumns}
                                    pagination={false}
                                />
                            </div>
                            <div>
                                <Typography.Title title={3}>총질문수 {studentQnaList?.length}</Typography.Title>
                                <Table
                                    rowKey={(record) => record?.id}
                                    dataSource={studentQnaList}
                                    columns={qnaColumns}
                                    pagination={false}
                                    expandable={{
                                        expandedRowRender: (record) => {
                                            const imageUrl = record.img ? record.img : record.original_img
                                            return <Image
                                                width={200}
                                                src={`${config.s3_url}/qna/${imageUrl}.jpg`}
                                            />
                                        }
                                    }}
                                    rowExpandable={(record) => !!(record.img || record.original_img)}
                                />
                            </div>
                        </div> :
                        <div>표시할 질문정보가 없습니다.</div>
                    }
                </div>
            }
            {
                !studentReportData && <div> 학생을 검색해주세요. </div>
            }
        </div>
    )
}

export default StudentSearch