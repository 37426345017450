import {observable, action, configure, makeObservable} from 'mobx';
import {createContext} from "react";

configure({ enforceActions: true });

class UserStore {
    @observable user = null;

    constructor() {
        makeObservable(this);
    }

    @action setUser = user => { this.user = user; }
}

const UserInfo = new UserStore()

const StoreContext = createContext(null)

export {UserInfo, StoreContext}
export default {UserInfo, StoreContext}
