import {Button, DatePicker, Image, InputNumber, Radio, Segmented, Space, Table, Typography} from "antd";
import moment from "moment";
import React, {useContext, useEffect, useState} from "react";
import config from "../../config";
import {StoreContext} from "../../store";
import {CartesianGrid, Legend, Line, LineChart, ResponsiveContainer, Tooltip, XAxis, YAxis} from "recharts";
import {Link} from "react-router-dom";
import styles from './style.module.scss'

moment.updateLocale('ko', {
    week: {
        dow: 1,
    }
})
const TeacherReport = ({classroomData}) => {
    const [endDate, setEndDate] = useState(null);
    const [timeScopeData, setTimeScopeData] = useState('w')
    const [timeNumberInputValue, setTimeNumberInputValue] = useState(5)
    const [qnaChartData, setQnaChartData] = useState([])
    const [teacherList, setTeacherList] = useState([])
    const [classList, setClassList] = useState([]);
    const [ansQnaChartData, setAnsQnaChartData] = useState([])
    const [ansQnaTeacherList, setAnsQnaTeacherList] = useState([])
    const [selectClassData, setSelectClassData] = useState('전체');
    const timeScopeOptions = [{label: '주', value: 'w'}, {label: '월', value: 'M'}]
    const UserInfo = useContext(StoreContext)

    useEffect(() => {
        let startDate;
        const getSummary = async () => {
            const qnaData = await (await fetch(config.apiUrl + '/admin/teacher/report', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    id: UserInfo.user.id,
                    start: moment(startDate).unix() * 1000,
                    end: endDate?.startOf('date').valueOf(),
                    classroomId: classroomData?.value,
                    classId: selectClassData === '전체' ? null : selectClassData,
                    // ansDate: true
                })
            })).json()
            const ansQnaData = await (await fetch(config.apiUrl + '/admin/teacher/report', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    id: UserInfo.user.id,
                    start: moment(startDate).unix() * 1000,
                    end: endDate?.startOf('date').valueOf(),
                    classroomId: classroomData?.value,
                    classId: selectClassData === '전체' ? null : selectClassData,
                    ansDate: true
                })
            })).json()
            adapterQnaLog(qnaData)
            adapterAnswerData(ansQnaData)
        }

        function parseDateStr(dateStr, type) {
            if (type === "w") {
                const parts = dateStr.match(/(\d+)년 (\d+)월 (\d+)주차/);
                return parts ? {
                    year: parseInt(parts[1], 10),
                    month: parseInt(parts[2], 10),
                    week: parseInt(parts[3], 10)
                } : null;
            } else if (type === "M") {
                const parts = dateStr.match(/(\d+)년 (\d+)월/);
                return parts ? {
                    year: parseInt(parts[1], 10),
                    month: parseInt(parts[2], 10),
                    week: 0 // 월만 있는 경우 주차를 0으로 처리
                } : null;
            }
        }

        function compareDates(a, b, type) {
            const dateA = parseDateStr(a, type);
            const dateB = parseDateStr(b, type);

            if (dateA.year !== dateB.year) {
                return dateA.year - dateB.year;
            } else if (dateA.month !== dateB.month) {
                return dateA.month - dateB.month;
            } else {
                return dateA.week - dateB.week;
            }
        }

        const adapterQnaLog = (qnaData) => {
            let setList = []
            let teacherList = []
            qnaData.map((qna) => {
                const {reg_date, tid, tid_HIQDB_user_credential} = qna.qid_HIQDB_online_qna
                const dateFormat = timeScopeData === 'w' ? 'YYYYMMDD' : 'YYYYMM'
                const date = moment.unix(reg_date).format(dateFormat)
                const week = moment(date, 'YYYYMMDD').week() - moment(date).startOf('month').week() + 1;
                const month = moment(date).month() + 1;
                const year = moment(date).year();
                const key = timeScopeData === 'w' ? `${year}년 ${month}월 ${week}주차` : `${year}년 ${month}월`
                const findIndex = setList.findIndex(({time}) => time === key)
                if (findIndex > -1) {
                    setList[findIndex][tid] = (setList[findIndex][tid] || 0) + 1
                    setList[findIndex]['qnaCount'] += 1
                } else {
                    setList.push({
                        time: key,
                        [tid]: 1,
                        qnaCount: 1
                    })
                }
                const findTeacher = teacherList.findIndex(({name}) => name === tid)
                if (findTeacher > -1) return
                else teacherList.push(tid_HIQDB_user_credential)
            })
            setQnaChartData(setList.sort((a, b) => compareDates(a.time, b.time, timeScopeData)))
            setTeacherList(teacherList)
        }
        const adapterAnswerData = (qnaData) => {
            let setList = []
            let teacherList = []
            qnaData.map((qna) => {
                const {ans_date, answered_by, answered_by_HIQDB_user_credential} = qna.qid_HIQDB_online_qna
                const dateFormat = timeScopeData === 'w' ? 'YYYYMMDD' : 'YYYYMM'
                const date = moment.unix(ans_date).format(dateFormat)
                const week = moment(date, 'YYYYMMDD').week() - moment(date).startOf('month').week() + 1;
                const month = moment(date).month() + 1;
                const year = moment(date).year();
                const key = timeScopeData === 'w' ? `${year}년 ${month}월 ${week}주차` : `${year}년 ${month}월`
                const findIndex = setList.findIndex(({time}) => time === key)
                if (findIndex > -1) {
                    setList[findIndex][answered_by] = (setList[findIndex][answered_by] || 0) + 1
                    setList[findIndex]['qnaCount'] += 1
                } else {
                    setList.push({
                        time: key,
                        [answered_by]: 1,
                        qnaCount: 1
                    })
                }
                const findTeacher = teacherList.findIndex(({name}) => name === answered_by)
                if (findTeacher > -1) return
                else teacherList.push(answered_by_HIQDB_user_credential)
            })
            setAnsQnaChartData(setList.sort((a, b) => compareDates(a.time, b.time, timeScopeData)))
            setAnsQnaTeacherList(teacherList)
        }
        if (endDate && timeNumberInputValue && selectClassData) {
            startDate = moment(endDate.startOf('date').valueOf()).subtract(timeNumberInputValue, timeScopeData).format('YYYY-MM-DD')
            getSummary()
        }
    }, [endDate, timeScopeData, timeNumberInputValue, classroomData, selectClassData])
    useEffect(() => {
        const getClassroomClassList = async () => {
            try {
                const res = await (await fetch(`${config.apiUrl}/admin/classroom/${classroomData?.value}/class`)).json();
                setClassList((prev) => {
                    const adapterClass = res.map((classData) => {
                        return {
                            label: classData.name,
                            value: classData.id
                        }
                    })
                    return [{label: '전체', value: '전체'}, ...adapterClass]
                })
            } catch (e) {
                console.error(e)
            }
        }
        getClassroomClassList()
    }, [classroomData])

    const chartLineRandomColor = () => {
        const randomValue = () => Math.floor(Math.random() * 256).toString(16).padStart(2, '0');
        const r = randomValue();
        const g = randomValue();
        const b = randomValue();
        return `#${r}${g}${b}`;
    }
    return (
        <div className={styles.studentReportContainer}>
            <Space direction={'vertical'}>
                <Space>
                    <Segmented options={timeScopeOptions} defaultValue={timeScopeData} onChange={setTimeScopeData}/>
                    <Typography.Text>기준 일</Typography.Text>
                    <DatePicker
                        placeholder={'start Date'}
                        value={endDate}
                        onChange={setEndDate}
                        disabledDate={(current) => current > moment().endOf('day')}
                    />
                    <InputNumber
                        min={1}
                        defaultValue={timeNumberInputValue}
                        onChange={setTimeNumberInputValue}
                    />
                    <Typography.Text>{timeScopeData === 'w' ? '주 전부터' : '개월 전부터'}</Typography.Text>
                    <Button>검색</Button>
                </Space>
                <Radio.Group
                    options={classList}
                    value={selectClassData}
                    style={{
                        marginTop: 16,
                    }}
                    optionType="button"
                    onChange={({target: {value}}) => setSelectClassData(value)}
                />
            </Space>
            <div>
                <Typography.Title>지목 질문 수</Typography.Title>
                <div style={{width: '60vw', height: '400px', maxWidth: '1200px', margin: '0 auto'}}>
                    <ResponsiveContainer width="100%" height="100%">
                        <LineChart
                            width={500}
                            height={300}
                            data={qnaChartData}
                            margin={{
                                top: 5,
                                right: 30,
                                left: 20,
                                bottom: 5,
                            }}
                        >
                            <CartesianGrid strokeDasharray="3 3"/>
                            <XAxis dataKey="time"/>
                            <YAxis/>
                            <Tooltip/>
                            <Legend/>
                            {teacherList.map(({name, user}) => {
                                return <Line name={user.name} dataKey={name} stroke={chartLineRandomColor()}
                                             connectNulls={true} strokeWidth={3}/>
                            })}
                        </LineChart>
                    </ResponsiveContainer>
                </div>
                <div>
                    <Table
                        rowKey={(record) => record?.id}
                        dataSource={qnaChartData}
                        pagination={false}
                    >
                        <Table.Column title={() => `기간 (${timeScopeData === 'w' ? '주' : '월'}간)`} dataIndex={'time'}
                                      align={'center'}/>
                        <Table.Column title={'전체질문수'} dataIndex={'qnaCount'} align={'center'}/>
                        {teacherList?.map(({name, id, user}) => {
                            return <Table.Column title={() => <Link to={name}>{user.name}</Link>} dataIndex={name}
                                                 align={"center"}/>
                        })}
                    </Table>
                </div>
                <Typography.Title>답변 완료 한 수</Typography.Title>
                <div style={{width: '60vw', height: '400px', maxWidth: '1200px', margin: '0 auto'}}>
                    <ResponsiveContainer width="100%" height="100%">
                        <LineChart
                            width={500}
                            height={300}
                            data={ansQnaChartData}
                            margin={{
                                top: 5,
                                right: 30,
                                left: 20,
                                bottom: 5,
                            }}
                        >
                            <CartesianGrid strokeDasharray="3 3"/>
                            <XAxis dataKey="time"/>
                            <YAxis/>
                            <Tooltip/>
                            <Legend/>
                            {ansQnaTeacherList.map(({name, user}) => {
                                return <Line name={user.name} dataKey={name} stroke={chartLineRandomColor()}
                                             connectNulls={true} strokeWidth={3}/>
                            })}
                        </LineChart>
                    </ResponsiveContainer>
                </div>
                <div>
                    <Table
                        rowKey={(record) => record?.id}
                        dataSource={ansQnaChartData}
                        pagination={false}
                    >
                        <Table.Column title={() => `기간 (${timeScopeData === 'w' ? '주' : '월'}간)`} dataIndex={'time'}
                                      align={'center'}/>
                        <Table.Column title={'전체질문수'} dataIndex={'qnaCount'} align={'center'}/>
                        {ansQnaTeacherList?.map(({name, id, user}) => {
                            return <Table.Column title={() => <Link to={name}>{user.name}</Link>} dataIndex={name}
                                                 align={"center"}/>
                        })}
                    </Table>
                </div>
            </div>


        </div>
    )
}

export default TeacherReport
