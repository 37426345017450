import React, {
  useContext,
  useEffect,
  useState,
  useCallback,
  useMemo,
} from "react";
import { useMutation, useQuery } from "@apollo/client";
import {
  Space,
  Typography,
  Row,
  Col,
  Button,
  Spin,
  Modal,
  notification,
  InputNumber,
} from "antd";
import styles from "./style.module.scss";
import useAcademy from "hooks/useAcademy";
import ALL_PAYBY_POLICIES from "graphql/allPaybyPolicies";
import ADD_PAYBY_POLICY from "graphql/addPaybyPolicy";
import UPDATE_PAYBY_POLICY from "graphql/updatePaybyPolicy";

const PolicyItem = ({ title, isSet, policy = {}, onClickAdd, onClickEdit }) => {
  const { validFrom, ratio, currentMonth } = policy || {};
  if (!isSet) {
    return (
      <Col style={{ alignItems: "center", padding: 0 }}>
        <Row>
          <Typography.Title level={5} mark style={{ margin: 0 }}>
            {title}
          </Typography.Title>
        </Row>
        <Row>
          <Col align="start">
            <Typography.Text strong>{"대납비율 정책 없음"}</Typography.Text>
          </Col>
        </Row>
        <Row style={{ gap: 8, justifyContent: "flex-end" }}>
          <Button onClick={onClickAdd}>추가</Button>
        </Row>
      </Col>
    );
  } else {
    return (
      <Col style={{ alignItems: "center", padding: 0 }}>
        <Row style={{ alignItems: "center", justifyContent: "space-between" }}>
          <Typography.Title level={5} mark style={{ margin: 0 }}>
            {policy?.title || title}
          </Typography.Title>
          <Typography.Text>
            {new Date(validFrom).toLocaleDateString("ko-KR", {
              year: "numeric",
              month: "long",
              day: "numeric",
            })}
            ~
          </Typography.Text>
        </Row>
        <Row>
          <Col style={{ width: 120 }} align="start">
            <Typography.Text strong>{"대납비율"}</Typography.Text>
          </Col>
          <Col flex={4} align="start">
            <Typography.Text>{ratio}</Typography.Text>
          </Col>
        </Row>
        <Row style={{ gap: 8, justifyContent: "flex-end" }}>
          {currentMonth ? (
            <Button onClick={onClickEdit}>수정</Button>
          ) : (
            <Button onClick={onClickAdd}>신규</Button>
          )}
        </Row>
      </Col>
    );
  }
};

const PolicyPage = ({ classroomData }) => {
  const [api, contextHolder] = notification.useNotification();
  const [editModalVisible, setEditModalVisible] = useState(false);
  const [title, setTitle] = useState();
  const [actionType, setActionType] = useState();
  const [policyId, setPolicyId] = useState();
  const [ratio, setRatio] = useState();

  const academyId = String(classroomData.value);
  const academy = useAcademy(academyId);
  const { loading, error, data, refetch } = useQuery(ALL_PAYBY_POLICIES, {
    variables: { academyId: academyId },
    fetchPolicy: "no-cache",
    skip: !academyId,
  });
  const [updatePolicy, { errors: updateError }] = useMutation(
    UPDATE_PAYBY_POLICY,
    { ignoreResults: true, onCompleted: refetch }
  );
  const [addPolicy, { errors: createError }] = useMutation(ADD_PAYBY_POLICY, {
    ignoreResults: true,
    onCompleted: refetch,
  });

  const paybyPolicies = data?.allPaybyPolicies;

  const setSelectedItem = (item) => {
    setActionType(item?.actionType);
    setPolicyId(item?.id);
    setRatio(item?.ratio);
  };

  const onClickEdit = useCallback(
    (actionType, title) => () => {
      const item = paybyPolicies?.filter(
        (item) => item.actionType == actionType
      )?.[0];
      setSelectedItem(item.policy);
      setTitle(title);
      setEditModalVisible(true);
    },
    [paybyPolicies]
  );

  const onClickAdd = useCallback(
    (actionType, title) => () => {
      setTitle(title);
      setActionType(actionType);
      setPolicyId();
      setRatio();
      setEditModalVisible(true);
    },
    [paybyPolicies]
  );

  const openNotification = useCallback(
    (type, message, description) => {
      api[type]({
        message,
        description,
        placement: "bottom",
      });
    },
    [api]
  );

  const validateInputs = useCallback(() => {
    if (ratio == undefined) {
      openNotification(
        "warning",
        "대납비율 미설정",
        "대납비율을 설정해주세요."
      );
      return false;
    }
    return true;
  }, [ratio]);

  const onSaveNew = useCallback(async () => {
    const valid = validateInputs();
    if (valid) {
      // call createPaybyPolicy mutation!
      let now = new Date();
      let year = now.getFullYear();
      let month = now.getMonth() + 1;
      let validFrom = `${year}-${String(month).padStart(2, "0")}-${String(
        1
      ).padStart(2, "0")}`;
      addPolicy({
        variables: { academyId: academyId, actionType, ratio, validFrom },
      });
      setSelectedItem();
      setEditModalVisible(false);
    }
  }, [academy, actionType, ratio, validateInputs]);

  const onSaveEdit = useCallback(async () => {
    const valid = validateInputs();
    if (valid) {
      updatePolicy({
        variables: {
          policyId: Number(policyId),
          ratio,
          academyId,
        },
      });
      setSelectedItem();
      setEditModalVisible(false);
    }
  }, [policyId, ratio, updatePolicy, academy]);

  const onCancelEdit = useCallback(() => {
    setActionType();
    setSelectedItem();
    setEditModalVisible(false);
  });

  if (!academy || loading) {
    return <Spin />;
  }
  return (
    <>
      {contextHolder}
      <div className={styles.policyContainer}>
        <Typography.Title level={4}>대납 비율 설정</Typography.Title>
        {paybyPolicies?.map((item, idx) => (
          <PolicyItem
            key={idx}
            onClickEdit={onClickEdit(item.actionType, item.title)}
            onClickAdd={onClickAdd(item.actionType, item.title)}
            {...item}
          />
        ))}
      </div>

      <Modal
        title={policyId ? "정책 수정" : "정책 생성"}
        open={editModalVisible}
        onOk={policyId ? onSaveEdit : onSaveNew}
        onCancel={onCancelEdit}
      >
        <Col
          style={{
            display: "flex",
            flexDirection: "column",
            width: "100%",
            alignItems: "stretch",
            padding: 0,
            gap: 8,
          }}
        >
          <Row>
            <Typography.Title level={5} mark>
              {title}
            </Typography.Title>
          </Row>
          <Row>
            <Col style={{ width: 120 }} align="start">
              <Typography.Text strong>{"대납 비율 (%)"}</Typography.Text>
            </Col>
            <Col flex={4} align="start">
              <InputNumber
                min={0}
                max={100}
                style={{ width: "100%" }}
                value={ratio}
                onChange={(value) => setRatio(value)}
              />
            </Col>
          </Row>
        </Col>
      </Modal>
    </>
  );
};

export default PolicyPage;
