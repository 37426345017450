import { Input } from "antd";
const { Search } = Input;
const SearchInput = ({ placeholder, onSearch, width }) => {
  const handleSearch = (value) => {
    onSearch(value);
  };
  return (
    <Search
      style={{ width: width }}
      placeholder={placeholder}
      onSearch={handleSearch}
      enterButton
    />
  );
};
export default SearchInput;
