import { gql } from "@apollo/client";

const GET_ALL_ACADEMY_BY_USER = gql`
  query getAllAcademyByUser($userId: String!) {
    getAllAcademyByUser(userId: $userId) {
      success
      error {
        code
        message
      }
      academies {
        id
        name
        location
        phone
        principal
        usePayment
        regId
        type
      }
    }
  }
`;

export default GET_ALL_ACADEMY_BY_USER;
