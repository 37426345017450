import { gql } from "@apollo/client";

const ADD_PAYBY_POLICY = gql`
  mutation AddPaybyPolicy(
    $academyId: String!
    $ratio: Float!
    $validFrom: String!
    $actionType: String!
  ) {
    addPaybyPolicy(
      academyId: $academyId
      ratio: $ratio
      validFrom: $validFrom
      actionType: $actionType
    ) {
      id
      title
      desc
      actionType
      ratio
      validFrom
      currentMonth
    }
  }
`;

export default ADD_PAYBY_POLICY;
