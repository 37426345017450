import React, {useEffect, useState} from "react";
import config from "../../config";
import moment from "moment/moment";
import {useParams} from "react-router-dom";
import {remove} from "mobx";
import {DatePicker, Image, Table, Tag, Typography} from "antd";

const TeacherDetailReport = ({classroomData}) => {
    const [classList, setClassList] = useState(null);
    const [teacherData, setTeacherData] = useState(null);
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    const [qnaDate, setQnaList] = useState(null);
    const [ansQnaList, setAnsQnaList] = useState(null);
    const [avgQnaData, setAvgQnaData] = useState(null);
    const params = useParams();

    const getTeacherDetailData = async () => {
        try {
            const res = await (await fetch(`${config.apiUrl}/admin/teacher/report/detail`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    id: params.id,
                    startDate: startDate?.startOf('date').valueOf(),
                    endDate: endDate?.startOf('date').valueOf(),
                    classroomId: classroomData?.value,
                })
            })).json()
            setAvgQnaData(() => {
                const {qnaList} = res;
                let setList = []
                qnaList?.map((qna) => {
                    const {ans_date, reg_date} = qna
                    const dateFormat = 'YYYYMM'
                    const date = moment.unix(reg_date).format(dateFormat)
                    const month = moment(date).month() + 1;
                    const year = moment(date).year();
                    const key = `${year}년 ${month}월`
                    const findIndex = setList.findIndex(({time}) => time === key)
                    if (findIndex > -1) {
                        setList[findIndex]['qnaCount'] += 1
                        setList[findIndex]['ansQnaCount'] += ans_date ? 1 : 0
                        if (ans_date) setList[findIndex]['avgQnaTime'].push((moment(ans_date * 1000).diff(reg_date * 1000, 'minutes') / 60).toFixed(1))
                    } else {
                        setList.push({
                            time: key,
                            qnaCount: 1,
                            ansQnaCount: ans_date ? 1 : 0,
                            avgQnaTime: ans_date ? [(moment(ans_date * 1000).diff(reg_date * 1000, 'minutes') / 60).toFixed(1)] : []
                        })
                    }
                })
                return setList
            })
            setClassList(res?.HIQDB_user_credentials[0]?.HIQDB_member_classes);
            setQnaList(res?.qnaList?.map((qna, index) => ({...qna, columnsId: index + 1})))
            setAnsQnaList(res?.ansQnaList?.map((qna, index) => ({...qna, columnsId: index + 1})))
            setTeacherData(res);
        } catch (e) {
            console.error(e)
        }
    }

    useEffect(() => {
        if (classroomData && startDate && endDate) {
            getTeacherDetailData()
        }
    }, [classroomData, startDate, endDate])

    useEffect(() => {
        if (classroomData) {
            getTeacherDetailData()
        }
    }, [])
    const qnaColumns = [
        {
            title: '문제 번호',
            dataIndex: 'columnsId',
            ket: 'columnsId',
            render: (id) => <span>{id}</span>,
            align: "center",
        },
        {
            title: '질문 일시',
            dataIndex: 'reg_date',
            key: 'reg_date',
            render: (date) => <span>{moment(date * 1000).format('YY-MM-DD HH:mm')}</span>,
            align: "center",
        },
        Table.EXPAND_COLUMN,
        {
            title: '문제 사진',
            dataIndex: 'img',
            key: 'img',
            render: (img, record) => <span>{(img || record.original_img) ? '사진 보기' : ''}</span>,
            align: "center",
        },
        {
            title: '답변 완료 일시',
            dataIndex: 'ans_date',
            key: 'ans_date',
            render: (ans_date) => {
                if (ans_date) {
                    return <span>{moment(ans_date * 1000).format('YY-MM-DD HH:mm')}</span>
                }
            },
            align: "center",
        },


    ]
    const classColumns = [
        {
            title: '수업명',
            dataIndex: ['cid_HIQDB_class', 'name'],
            ket: ['cid_HIQDB_class', 'name'],
            align: "center",
        },
        {
            title: '권한',
            dataIndex: 'type',
            ket: 'type',
            render: (type) => {
                let teacherType = '일반 선생님'
                let tagColor = ''
                if (type === 5) {
                    teacherType = '수업 관리자'
                    tagColor = 'blue'
                } else if (type === 7) {
                    teacherType = '조교'
                    tagColor = '#F5B21A'
                }
                return <Tag color={tagColor}>{teacherType}</Tag>
            },
            align: "center",
        },
    ]
    const avgColumns = [
        {
            title: '주별 통계',
            dataIndex: 'time',
            ket: 'time',
            align: "center",
        },
        {
            title: '지목 질문수',
            dataIndex: 'qnaCount',
            ket: 'qnaCount',
            align: "center",
        },
        {
            title: '답변 완료수',
            dataIndex: 'ansQnaCount',
            ket: 'ansQnaCount',
            align: "center",
        },
        {
            title: '응답시간',
            dataIndex: 'avgQnaTime',
            ket: 'avgQnaTime',
            render: (avgResponseTime, record) => {
                if (avgResponseTime.length === 0) return
                return <span>{(avgResponseTime?.reduce((acc, time) => acc + Number(time), 0) / record.ansQnaCount).toFixed(1)}</span>
            },
            align: "center",
        },
    ]
    return (
        <div>
            <Typography.Title>{teacherData?.name} ({teacherData?.nick}) 선생님</Typography.Title>
            <DatePicker.RangePicker
                placeholder={['시작일', '종료일']}
                disabledDate={(current) => current > moment().endOf('day')}
                onChange={(dates) => {
                    if (dates) {
                        const [start, end] = dates
                        setStartDate(start)
                        setEndDate(end)
                    }
                }}/>
            <div>
                <Typography.Text>참여중인 수업</Typography.Text>
                <Table dataSource={classList} rowKey={(record) => record.id} columns={classColumns}/>
            </div>
            <div>
                <Typography.Text>선생님 평균</Typography.Text>
                <Table dataSource={avgQnaData} rowKey={(record) => record.id} columns={avgColumns}/>
            </div>
            <div>
                <Typography.Text>지목받은 질문</Typography.Text>
                <Table
                    rowKey={(record) => record?.id}
                    dataSource={qnaDate}
                    columns={qnaColumns}
                    expandable={{
                        expandedRowRender: (record) => {
                            const imageUrl = record.img ? record.img : record.original_img
                            return <Image
                                width={200}
                                src={`${config.s3_url}/qna/${imageUrl}.jpg`}
                            />
                        }
                    }}
                    rowExpandable={(record) => !!(record.img || record.original_img)}
                />
            </div>
            <div>
                <Typography.Text>답변 완료 한 질문</Typography.Text>
                <Table
                    rowKey={(record) => record?.id}
                    dataSource={ansQnaList}
                    columns={qnaColumns}
                    expandable={{
                        expandedRowRender: (record) => {
                            const imageUrl = record.img ? record.img : record.original_img
                            return <Image
                                width={200}
                                src={`${config.s3_url}/qna/${imageUrl}.jpg`}
                            />
                        }
                    }}
                    rowExpandable={(record) => !!(record.img || record.original_img)}
                />
            </div>
        </div>
    )
}
export default TeacherDetailReport